

$(document).ready(function() {
  // 手機側邊選單
  $('nav#menu').mmenu({
    drag: true,
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'sticky-top'
      }
    }
  })

  $('.page-header').sticky({topSpacing: 0})

  $('.dropdown-hover').dropdownHover(100)

  // $('#pageHeader').sticky({topSpacing: 0})
  // $('#pageHeader').on('sticky-end', function () { $(this).parent('.sticky-wrapper').css({height: 'auto'}) }) // sticky 補丁

  // $().UItoTop({
  //   easingType: 'easeOutQuart',
  //   text: ''
  // })
  //-$('.dropdown-hover').dropdownHover(100)
  // const burger = document.querySelector('#burgerButton'),
  //       header = document.querySelector('.page-header'),
  //       body = document.body;
  // let showMenu = false
  // burger.onclick = function() {
  //     header.classList.toggle('menu-opened');
  //     body.classList.toggle('menu-show')
  //     showMenu = !showMenu
  //     console.log(showMenu);
  // }

  $('#heroBanner').slick({
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    speed: 600,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        }
      }
    ]
  })

  $('#twzipcode').twzipcode();

  $('.video-btn').click(function() {
      const $vid = $(this).data( "vid" );
      let url = "https://www.youtube.com/embed/"+$vid+"?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
      $("#video").attr('src',url);
      console.log($vid);
  });

  $('#videoModal').on('hidden.bs.modal', function (e) {
    console.log('hidden');
    $("#video").attr('src',"");
  })

});

